import React from 'react';

import { PAGES } from '../../../utils/utils';
import ChildCustody from '../../../components/en/expertise/child-custody';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const ChildCustodyPage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_GARDE_ENFANTS}>
    <SEO title="Child Custody" lang="en" />
    <ChildCustody />
  </Layout>
);

export default ChildCustodyPage;
