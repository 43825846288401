import React from 'react';

import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const ChildCustody = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.CHILD_CUSTODY}>
      <h1>Child Custody</h1>

      {generateBreadcrumb('child custody')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="garde"
          src="/img/competences_sprite.jpg"
          alt="Child Custody"
        />
      </div>

      <p>
        Resolving differences concerning{' '}
        <span className="accent">child custody</span> and parents'{' '}
        <span className="accent">visiting rights</span> has a significant impact
        on the family. Our lawyers are <span className="accent2">patient</span>,{' '}
        <span className="accent2">diplomat</span> and{' '}
        <span className="accent2">determined</span> when solving these problems,
        and always keep as primary objective the best interest of the child.
      </p>
    </ExpertiseContainer>
  );
};

ChildCustody.propTypes = {};

export default ChildCustody;
